import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "@/components/Loader";
import { Card } from "@/components/ui/card";
import PieChart from "@/components/PieChart";
import BarChart from "@/components/BarChart";
import TrendChart from "@/components/TrendChart";
import DisputeCard from "@/components/DisputeCard";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";

import fetchAllDisputes from "@/scripts/fetchAllDisputes";
import fetchAllNeutrals from "@/scripts/fetchAllNeutrals";

function Dashboard() {
  const navigate = useNavigate();
  const [disputes, setDisputes] = useState([]);
  const [neutrals, setNeutrals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const disputeList = await fetchAllDisputes(); // limit value
      setDisputes(disputeList);
      const neutralList = await fetchAllNeutrals();
      setNeutrals(neutralList);
    };
    fetchData();
  }, []);

  if (!disputes || !neutrals) {
    return <Loading />;
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div className="flex justify-between">
        <h2 className="text-md text-gray-600 font-bold">Dispute Registry</h2>
        <button
          onClick={() => navigate("/dispute-registry")}
          className="text-sm text-gray-600 font-semibold"
        >
          See all
        </button>
      </div>

      <div className="flex gap-6 w-100 mt-3">
        {disputes[0] && <DisputeCard data={disputes[0]} />}
        {disputes[1] && <DisputeCard data={disputes[1]} />}
        {disputes[2] && <DisputeCard data={disputes[2]} />}
      </div>

      <h2 className="text-md text-gray-600 font-bold mt-2">
        Dashboard Statistics
      </h2>
      <div className="flex justify-between">
        <BarChart data={disputes} />
        <PieChart data={disputes} />
      </div>

      <div className="flex justify-between mt-5 mb-5">
        <div className="flex flex-col w-[49%]">
          <h2 className="text-md text-gray-600 font-bold">Approved Neutrals</h2>

          <Card className="h-full grid grid-cols-2 sm:grid-cols-3 gap-6 p-5 bg-white mt-3 rounded-lg">
            {neutrals.slice(0, 6).map((neutral, index) => (
              <div key={index} className="flex flex-col items-center">
                <Avatar className="w-16 h-16">
                  {/* <AvatarImage src={neutral.image} alt={user.name} /> */}
                  <AvatarFallback className="font-semibold bg-blue-200">
                    {neutral.name.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                <span className="mt-2 text-sm font-medium text-gray-600">
                  {neutral.name}
                </span>
              </div>
            ))}
          </Card>
        </div>

        <div className="flex flex-col w-[49%]">
          <h2 className="text-md text-gray-600 font-bold mb-3">
            Number of disputes resolved
          </h2>
          <TrendChart data={disputes} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
