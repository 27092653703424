import { useState, useEffect } from "react";
import Loader from "@/components/Loader";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "@/services";
import { ArrowLeft } from "lucide-react";

const CopyInput = ({ label, value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="mb-3">
      <label className="block text-sm font-bold text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex">
        <input
          type="text"
          readOnly
          value={value}
          className="flex-1 px-3 py-2 border rounded-l bg-gray-50 text-sm"
        />
        <button
          onClick={handleCopy}
          className="px-3 py-2 bg-blue-500 text-white rounded-r text-sm hover:bg-blue-600"
        >
          Copy
        </button>
      </div>
    </div>
  );
};

function Payment() {
  const navigate = useNavigate();
  const { paymentId } = useParams();
  const [payment, setPayment] = useState();
  const [loading, setLoading] = useState(true);

  const fetchPayment = async () => {
    try {
      setLoading(true);
      const { data } = await apiClient.post("/fetchPayment", {
        payment_id: paymentId,
      });
      setPayment(data);
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  if (loading) return <Loader />;

  if (!payment) {
    return (
      <div
        style={{
          minHeight: "calc(100vh - 72px)",
          padding: "20px",
          backgroundColor: "#F6F5F9",
        }}
      >
        <div className="flex flex-row items-center gap-2">
          <ArrowLeft color="grey" onClick={() => navigate(-1)} />

          <h2 className="text-lg text-gray-600 font-bold">
            Payment Information
          </h2>
        </div>

        <div className="border p-4 my-4 bg-red-100 text-red-700 rounded">
          <h3 className="text-md font-semibold">❌ Payment Error</h3>
          {/* {Object.entries(payment.error).map(([key, val]) => (
          <CopyInput key={key} label={key} value={val} />
        ))} */}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <div className="flex flex-row items-center gap-2">
        <ArrowLeft color="grey" onClick={() => navigate(-1)} />

        <h2 className="text-lg text-gray-600 font-bold">Payment Information</h2>
      </div>

      <div className="border p-4 mt-3 mb-6 bg-white shadow rounded">
        <h3 className="text-lg font-semibold mb-3">✅ Payment Details</h3>

        <CopyInput label="Payment ID" value={payment.id} />
        <CopyInput label="Status" value={payment.status} />
        <CopyInput label="Amount" value={`₹${payment.amount / 100}`} />
        <CopyInput label="Currency" value={payment.currency} />
        <CopyInput label="Method" value={payment.method} />
        <CopyInput label="Order ID" value={payment.order_id} />
        {payment.invoice_id && (
          <CopyInput label="Invoice ID" value={payment.invoice_id} />
        )}
        {payment.description && (
          <CopyInput label="Description" value={payment.description} />
        )}
        {payment.card_id && (
          <CopyInput label="Card ID" value={payment.card_id} />
        )}
        {payment.bank && <CopyInput label="Bank" value={payment.bank} />}
        {payment.wallet && <CopyInput label="Wallet" value={payment.wallet} />}
        {payment.vpa && <CopyInput label="VPA" value={payment.vpa} />}
        {payment.email && <CopyInput label="Email" value={payment.email} />}
        {payment.contact && (
          <CopyInput label="Contact" value={payment.contact} />
        )}
        {payment.fee != null && (
          <CopyInput label="Fee" value={`₹${payment.fee / 100}`} />
        )}
        {payment.tax != null && (
          <CopyInput label="Tax" value={`₹${payment.tax / 100}`} />
        )}
        {payment.customer_id && (
          <CopyInput label="Customer ID" value={payment.customer_id} />
        )}
        {payment.token_id && (
          <CopyInput label="Token ID" value={payment.token_id} />
        )}
        {payment.acquirer_data && (
          <CopyInput
            label="Acquirer Data"
            value={JSON.stringify(payment.acquirer_data, null, 2)}
          />
        )}
        <CopyInput
          label="Created At"
          value={new Date(payment.created_at * 1000).toLocaleString()}
        />
      </div>
    </div>
  );
}

export default Payment;
