import React, { useState, useEffect } from "react";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { firestore } from "@/firebase/config";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Loader from "@/components/Loader";
import fetchAllClients from "@/scripts/fetchAllClients";

function HighlightEvent({ event }) {
  const highlights = {
    "Case Payment: Pending": "text-red-500",
    "Case Payment: Completed": "text-blue-600",
    "Filled: Party B Details": "text-green-500",
    "Filled: Case Information": "text-green-500",
  };

  return <span className={highlights[event] || ""}>{event}</span>;
}

function formatDate(event) {
  return event.created_at
    ? new Date(event.created_at).toLocaleString("en-IN", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      })
    : "—";
}

function ClientEventList() {
  const [clientsWithEvents, setClientsWithEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchUserEventsForClient = async (userId) => {
    const q = query(
      collection(firestore, "user_events"),
      where("user_id", "==", userId),
      orderBy("created_at", "desc")
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => doc.data());
  };

  useEffect(() => {
    const getData = async () => {
      const clientList = await fetchAllClients();

      const enrichedClients = await Promise.all(
        clientList.map(async (client) => {
          const events = await fetchUserEventsForClient(client.users_id);
          return {
            ...client,
            events,
            latestEventTime: events[0]?.created_at?.toMillis?.() || 0,
          };
        })
      );

      // Sort by latest event timestamp
      enrichedClients.sort((a, b) => b.latestEventTime - a.latestEventTime);

      setClientsWithEvents(enrichedClients);
      setLoading(false);
    };

    getData();
  }, []);

  if (loading) return <Loader />;

  return (
    <div className="p-6 bg-[#F6F5F9] min-h-screen">
      <h2 className="text-md text-gray-700 font-bold mb-4">
        User Event History
      </h2>

      <Card className="p-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="font-bold">Sno</TableHead>
              <TableHead className="font-bold">Name</TableHead>
              <TableHead className="font-bold">Phone</TableHead>
              <TableHead className="font-bold">Events</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {clientsWithEvents.map((client, idx) => (
              <TableRow key={idx}>
                <TableCell className="font-medium">{idx + 1}</TableCell>
                <TableCell className="font-medium">
                  {client.name || "—"}
                </TableCell>
                <TableCell>{client.phone ? client.phone : "—"}</TableCell>
                <TableCell>
                  <ul className="list-disc pl-4 space-y-1 text-sm">
                    {client.events.length > 0 ? (
                      client.events.map((event, index) => (
                        <li
                          key={index}
                          className="flex items-center justify-between"
                        >
                          <HighlightEvent event={event.event} />{" "}
                          <span className="text-gray-500 text-xs">
                            {formatDate(event)}
                          </span>
                        </li>
                      ))
                    ) : (
                      <p className="text-gray-500 text-sm">No events found.</p>
                    )}
                  </ul>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default ClientEventList;
