import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firestore } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";
import { ArrowLeft } from "lucide-react";
import Loader from "@/components/Loader";

const CopyInput = ({ label, value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="mb-3">
      <label className="block text-sm font-bold text-gray-700 mb-1">
        {label}
      </label>
      <div className="flex">
        <input
          type="text"
          readOnly
          value={value || "Not available"}
          className="flex-1 px-3 py-2 border rounded-l bg-gray-50 text-sm"
        />
        {value && (
          <button
            onClick={handleCopy}
            className="px-3 py-2 bg-blue-500 text-white rounded-r text-sm hover:bg-blue-600"
          >
            Copy
          </button>
        )}
      </div>
    </div>
  );
};

const fetchBankDetails = async (userId) => {
  try {
    const bankRef = doc(firestore, "bank_details", userId);
    const docSnapshot = await getDoc(bankRef);

    if (docSnapshot.exists()) {
      console.log("Bank details fetched successfully");
      return docSnapshot.data();
    } else {
      console.log("No bank details found for user");
      return null;
    }
  } catch (error) {
    console.error("Error fetching bank details: ", error.message);
    throw error;
  }
};

function BankDetails() {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [bankDetails, setBankDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const details = await fetchBankDetails(uid);
        setBankDetails(details);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (uid) loadData();
  }, [uid]);

  if (loading) return <Loader />;

  if (error) {
    return (
      <div className="min-h-[calc(100vh-72px)] p-5 bg-gray-50">
        <div className="flex items-center gap-2 mb-4">
          <ArrowLeft color="grey" onClick={() => navigate(-1)} />
          <h2 className="text-lg font-bold text-gray-600">Bank Details</h2>
        </div>
        <div className="p-4 bg-red-100 text-red-700 rounded border border-red-200">
          <h3 className="font-semibold">Error Loading Bank Details</h3>
          <p className="mt-1">{error}</p>
        </div>
      </div>
    );
  }

  if (!bankDetails) {
    return (
      <div className="min-h-[calc(100vh-72px)] p-5 bg-gray-50">
        <div className="flex items-center gap-2 mb-4">
          <ArrowLeft color="grey" onClick={() => navigate(-1)} />
          <h2 className="text-lg font-bold text-gray-600">Bank Details</h2>
        </div>
        <div className="p-4 bg-yellow-100 text-yellow-800 rounded border border-yellow-200">
          <h3 className="font-semibold">No Bank Details Found</h3>
          <p className="mt-1">
            This user hasn't set up their bank information yet.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-[calc(100vh-72px)] p-5 bg-gray-50">
      <div className="flex items-center gap-2 mb-4">
        <ArrowLeft color="grey" onClick={() => navigate(-1)} />
        <h2 className="text-lg font-bold text-gray-600">Bank Details</h2>
      </div>

      <div className="bg-white p-4 rounded shadow border">
        <h3 className="text-lg font-semibold mb-4">Bank Account Information</h3>

        <CopyInput
          label="Account Holder Name"
          value={bankDetails.accountName}
        />
        <CopyInput label="Bank Name" value={bankDetails.bankName} />
        <CopyInput label="Account Number" value={bankDetails.accountNumber} />
        <CopyInput label="IFSC Code" value={bankDetails.branchCode} />
        <CopyInput label="Branch Name" value={bankDetails.branchName} />
      </div>
    </div>
  );
}

export default BankDetails;
