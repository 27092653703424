import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Card } from "@/components/ui/card";
import Loader from "@/components/Loader";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select";
import { firestore } from "@/firebase/config";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  orderBy,
  query,
} from "firebase/firestore";
import formatDate from "@/scripts/formatDate";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

function PaymentList() {
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [purposeFilter, setPurposeFilter] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const fetchPaymentsWithUserData = async () => {
    const paymentsQuery = query(
      collection(firestore, "payments"),
      orderBy("created_at", "desc")
    );
    const paymentsSnapshot = await getDocs(paymentsQuery);

    const payments = paymentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const mergedData = await Promise.all(
      payments.map(async (payment) => {
        const userRef = doc(firestore, "users", payment.user_id);
        const userSnap = await getDoc(userRef);

        return {
          ...payment,
          userName: userSnap.exists() ? userSnap.data().name : "Unknown",
          userPhone: userSnap.exists() ? userSnap.data().phone : "Unknown",
        };
      })
    );

    setPaymentData(mergedData);
    setFilteredData(mergedData);
    setLoading(false);
  };

  const handleFilters = () => {
    const q = searchQuery.toLowerCase();
    const filtered = paymentData.filter((item) => {
      const matchSearch =
        item.userName?.toLowerCase().includes(q) ||
        item.userPhone?.toString().includes(q) ||
        item.purpose?.toLowerCase().includes(q);

      const matchStatus =
        statusFilter === "all" || item.status === statusFilter;
      const matchPurpose =
        purposeFilter === "all" || item.purpose === purposeFilter;

      const createdAtDate =
        item.created_at?.toDate?.() || new Date(item.created_at);
      const matchStartDate = startDate
        ? createdAtDate >= new Date(startDate)
        : true;
      const matchEndDate = endDate
        ? createdAtDate <= new Date(new Date(endDate).setHours(23, 59, 59, 999))
        : true;

      return (
        matchSearch &&
        matchStatus &&
        matchPurpose &&
        matchStartDate &&
        matchEndDate
      );
    });

    setFilteredData(filtered);
  };

  useEffect(() => {
    fetchPaymentsWithUserData();
  }, []);

  useEffect(() => {
    handleFilters();
  }, [
    searchQuery,
    statusFilter,
    purposeFilter,
    startDate,
    endDate,
    paymentData,
  ]);

  if (loading) return <Loader />;

  return (
    <div
      style={{
        minHeight: "calc(100vh - 72px)",
        padding: "20px",
        backgroundColor: "#F6F5F9",
      }}
    >
      <h2 className="text-md text-gray-600 font-bold mb-2">Payments</h2>

      {/* Filters */}
      <Card className="p-4 mb-4 flex flex-col md:flex-row flex-wrap gap-4">
        <div className="flex flex-col w-full md:w-[350px]">
          <label className="text-sm text-gray-600 mb-1">Search</label>
          <Input
            placeholder="Search name, phone or purpose"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="flex flex-col w-[160px]">
          <label className="text-sm text-gray-600 mb-1">Status</label>
          <Select value={statusFilter} onValueChange={setStatusFilter}>
            <SelectTrigger>
              <SelectValue placeholder="Filter by Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="success">Success</SelectItem>
              <SelectItem value="failed">Failed</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col w-[160px]">
          <label className="text-sm text-gray-600 mb-1">Purpose</label>
          <Select value={purposeFilter} onValueChange={setPurposeFilter}>
            <SelectTrigger>
              <SelectValue placeholder="Filter by Purpose" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="case_registration_payment">
                Case Registration
              </SelectItem>
              <SelectItem value="case_settlement_payment">
                Case Settlement
              </SelectItem>
              <SelectItem value="counsellor_payment">
                Counsellor Payment
              </SelectItem>
              <SelectItem value="prime_membership_payment">
                Prime Membership Payment
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col w-[160px]">
          <label className="text-sm text-gray-600 mb-1">Start Date</label>
          <Input
            type="date"
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
          />
        </div>

        <div className="flex flex-col w-[160px]">
          <label className="text-sm text-gray-600 mb-1">End Date</label>
          <Input
            type="date"
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
          />
        </div>

        <div className="flex flex-col justify-end">
          <Button
            onClick={() => {
              setSearchQuery("");
              setStatusFilter("all");
              setPurposeFilter("all");
              setStartDate("");
              setEndDate("");
            }}
          >
            Reset
          </Button>
        </div>
      </Card>

      <Card className="p-4 overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[60px] font-bold">Sno.</TableHead>
              <TableHead className="font-bold">Name</TableHead>
              <TableHead className="font-bold">Phone</TableHead>
              <TableHead className="font-bold">Amount</TableHead>
              <TableHead className="font-bold">Currency</TableHead>
              <TableHead className="font-bold">Status</TableHead>
              <TableHead className="font-bold">Purpose</TableHead>
              <TableHead className="font-bold">Created At</TableHead>
              <TableHead className="font-bold">Payment ID</TableHead>
              <TableHead className="font-bold">Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredData.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.userName}</TableCell>
                <TableCell>{item.userPhone}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{item.currency}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.purpose}</TableCell>
                <TableCell>{formatDate(item.created_at)}</TableCell>
                <TableCell>{item.payment_id}</TableCell>
                <TableCell>
                  <Button
                    onClick={() => {
                      navigate(`/payment/${item.payment_id}`);
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

export default PaymentList;
