import axios from "axios";
import { auth } from "../firebase/config";

const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL
      : process.env.REACT_APP_FIREBASE_FUNCTIONS_DEV_URL,
});

// Set up Axios request interceptor to refresh token
apiClient.interceptors.request.use(
  async (config) => {
    try {
      // Always refresh the token before making any request
      const idToken = await auth.currentUser.getIdToken(true);
      config.headers.Authorization = `Bearer ${idToken}`;
      config.headers["Content-Type"] = "application/json";
      return config;
    } catch (error) {
      console.error("Error refreshing token: ", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
